.raport-property-list-item{
  display:block;
  position:relative;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,.08);
a {
  transition: color .15s linear;
}
  .raport-property-list-image{
    width: 15%;
    float:left;
    position:relative;
    background-color: rgba(0, 0, 0, 0.15);

    &:hover:after{
      opacity: .5;
    }
    &:before {
      color: rgba(0, 0, 0, 0.2);
      content: "";
      display: block;
      font-family: 'FontAwesome';
      font-size: 40px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      z-index: 0;
    }

    &:after{
      background-color: black;
      content: '';
      font-family: 'FontAwesome';
      display: table-cell;
      height: 100%;
      left: 0px;
      opacity: 0;
      color: #fff;
      position: absolute;
      text-align: center;
      top: 0px;
      line-height: 150px;
      transition: opacity .15s linear;
      vertical-align: middle;
      width: 100%;
      z-index: 3;
    }

    img{
      display: block;
      position:relative;
      vertical-align:middle;
    }
  }

  .raport-property-list-info{
    width: 80%;
    float:left;
    padding-left: 20px;
  }

}

.report-module{
  margin-bottom: 50px;
}

.report-module-box{
  display:block;
  position:relative;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,.09);
  padding: 15px;
  background: rgba(0,0,0,.02);
}

.landlord-back{
  display: block;
  font-size: 16px;
  margin: 20px 0;

}