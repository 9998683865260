.invitebanner{
  background: #fff;
  padding: 30px 25px 30px 15px;
  border-radius: 5px;
  .invite-title{
    font-size: 24px;
    font-weight: bold;
  }

  .btn{
    font-size: 13px;
    letter-spacing: 0.2px;
    // padding: 10px 35px 10px 35px !important;
    color: white;
    border-radius: 5px;
    background: @brand-color;
    text-transform: uppercase;
    &:hover{
      color: white;
      text-decoration: none;
      background: fade(@brand-color, 80%);
    }
  }
}