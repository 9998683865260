.imagetext-module{
  height: 190px;
  //padding:45px;
  position: relative;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);


}

.imagetext-module-title{
position:relative;

  h3{
    font-weight: 400;
  }
    &:after{
      content:" ";
      display:block;
      width:50px;
      border-bottom:1px solid #fff;
      position:absolute;
      left:-30px;
      bottom:-20px;
      -webkit-transition: all 0.5s ease;
      -moz-transition:    all 0.5s ease;
      -ms-transition:     all 0.5s ease;
      -o-transition:      all 0.5s ease;

    }

  &:before{
    content:" ";
    display:block;
    width:50px;
    border-bottom:1px solid #fff;
    text-align:right;
    position:absolute;
    right:-30px;
    top:-20px;
    -webkit-transition: all 0.5s ease;
    -moz-transition:    all 0.5s ease;
    -ms-transition:     all 0.5s ease;
    -o-transition:      all 0.5s ease;
  }
}
.imagetext-module-text-wrapper{
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  text-align:center;
  padding:70px 30px 20px 30px;
  //#gradient > .vertical(rgba(0,0,0,0), rgba(0,0,0,.7));
  background: rgba(119,119,119,0.7);

  h1,h2,h3,h4, h5, p{
    color: #fff;
    text-align:center;
    font-weight: bold;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8);
  }

  h1,h2,h3{
    font-size: 30px;
    font-weight: 400;
  }

}

.imagetext-module-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:hover {
    .imagetext-module-text-wrapper {
      background: fade(@brand-color, 70%);
    }

    .imagetext-module-title{
      &:after{
        bottom: 75px;
      }

      &:before{
        top: 75px;
      }
    }

  }

}