.menu-module{
  list-style:none;
  li{
    margin-bottom: 0;
  }
  .columns-2{
    width: 50%;
    padding-right:10px;
    float:left;
  }

  .columns-3{
    width: 33%;
    padding-right:10px;
    float:left;
  }
}