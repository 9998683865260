.text-icon-content{
  padding: 30px;
  background: @mycolor-grey;
  margin-right:-15px;
  min-height: 400px;
  position:relative;
  h3{
    text-align:left;
    font-size:40px;
    font-weight: 400;
  }
}

.text-icon-over{
  position:relative;
  z-index:2;
}
.text-icon-bg{
  background: @mycolor-grey;
  top:0;
  bottom:0;
  width: 49vw;
  right:0;
  left:auto;
  display:block;
  position:absolute;
  overflow: hidden;
  z-index:1;
}

.alternate .text-icon-content{
  margin-right:0;
  margin-left:-15px;

  .text-icon-bg{
    right:auto;
    left:0;
  }
}


.text-icon-icon{
  border: 10px solid #F3F4F6;
  border-radius: 100%;
  display: block;
  width:230px;
  height:230px;
  line-height:230px;
  text-align:center;
  margin:100px auto 0 auto;

  .flaticon:before, .flaticon:after{
    font-size: 130px !important;
  }
  i{
    font-size: 100px !important;
    text-align: center;
    line-height: 200px;
    color: @mycolor-green;

    &:before, &:after{
      font-size: 120px !important;
    }
  }

}
a.text-icon-link{
  text-decoration: none !important;

  &:hover{
    .text-icon{
    background: @brand-color3;
      h3, p, i{
       // color: #fff;
      }
    }
  }
}
