.testimonial-slider-item{
  width: 100%;
  margin:0 auto;
  position:relative;
  margin-bottom: 30px;
  padding-bottom: 30px;

  .testimonial-slider-item-content{
    font-size: 16px;
    font-weight: 300;
    display: block;
    //overflow: hidden;
    padding:30px;
    background: @mycolor-grey;
    position: relative;
    color: #000;
   // width:70%;
    .fa{
      display:none;
    }
    &:after{
      content: "";
      position: absolute;
      top:40px;
      left: -20px;
      width: 0;
      height: 0;
      border-style: solid;
      border:0;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;

      border-right:30px solid @mycolor-grey;
    }
  }

  .testimonial-slider-item-image{
    display: block;
    max-width: 90px;
    border-radius:50%;
    overflow: hidden;
    min-height: 50px;
    margin:0 auto;

   // width:20%;
  }
  .testimonial-slider-item-author{

    display:block;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
}


.testimonials-wrapper{
  width:100%;
  margin-top:20px;
}
.testimonial-item{

  margin:0 auto;
  position:relative;
  margin-bottom: 20px;

  .testimonial-item-content{
    font-size: 16px;
    font-weight: 300;
    display: block;
    overflow: hidden;
    // width:70%;
  }

  .testimonial-item-image{
    display: block;
    float:right;
    margin-right:15px;
    max-width: 60px;
    border-radius:50%;
    overflow: hidden;
    margin-top:-20px;
    // width:20%;
  }
  .testimonial-item-author{

    display:block;
    float:right;
    font-size: 24px;
    font-weight: 300;
  }
}