.bx-wrapper .bx-next:after{
  font-family: "FontAwesome";
  content:"\f054";
  text-indent: 0 !important;
  font-size: 20px;
  color: #fff;
}

.bx-wrapper{
  margin-bottom: 0;
}

#property-main-gallery-pager {
  text-align:center;
  margin-top: 15px;

  a img {
    max-width: 100px;
  }
}
#property-main-gallery-pager a.active{
  opacity: .5;
}
.bx-wrapper .bx-pager{
  bottom: 10px !important;
}