.map-canvas{
  min-height: 350px;
}

.infowindow-wrapper{
 // max-width: 150px;
}

#transportation, #supermarkets, #schools, #libraries, #pharmacies, #hospitals {
  background-color: #fff;
  cursor: pointer;
  text-align: center;
  float: left;
  clear: left;
  position: relative;
  white-space: nowrap;
  height: 40px;
  margin-bottom: 1px;
}

#transportation .icon, #supermarkets .icon, #schools .icon, #libraries .icon, #pharmacies .icon, #hospitals .icon {
  width: 40px;
  height: 40px;
  padding: 4px;
  position: relative;
}

#transportation img, #supermarkets img, #schools img, #libraries img, #pharmacies img, #hospitals img {
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

#transportation.active span, #supermarkets.active span, #schools.active span, #libraries.active span, #pharmacies.active span, #hospitals.active span {
  z-index: 1;
  -moz-transform: translate3d(100%,0,0);
  -o-transform: translate3d(100%,0,0);
  -ms-transform: translate3d(100%,0,0);
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;

}

#transportation span, #supermarkets span, #schools span, #libraries span, #pharmacies span, #hospitals span {
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  -moz-transform: translate3d(48%, 0, 0);
  -o-transform: translate3d(48%, 0, 0);
  -ms-transform: translate3d(48%, 0, 0);
  -webkit-transform: translate3d(48%, 0, 0);
  transform: translate3d(48%, 0, 0);
  padding-right: 12px;
  padding-left: 16px;
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
  visibility: hidden;
}