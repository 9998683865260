.bx-slider{
  .bx-wiewport{
    background: none;
  }
}

.bx-wrapper{
  background: none;
}

.slide-title{
  text-align:center;
  color: #fff;
  display:block;
}

.owl-carousel .owl-item {
  .slideshow-item {
    width: 100%;
    img {
      width: auto;
      margin:0 auto;
    }
  }
}