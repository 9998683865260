.properties-categories-short{
  color: #fff;
  display: inline-block;
  font-size: 13px;
  margin-right:15px;
  background: rgba(0,0,0,.2);
  border-radius: 4px;
  padding:5px;

  &:last-of-type{
    //margin-right:0;
  }

  &:hover{
    // color: @mycolor-red;
    color: #fff;
    text-decoration: none;
    background: rgba(0,0,0,.5);
    i{
      color: #fff;
      // background: @mycolor-red;

    }
  }

  i{
    display: block;
    width: 100px;
    height: 70px;
    line-height: 70px;

    text-align: center;

    &:before{
      font-size: 45px;
    }
  }
}

@media (max-width: @screen-sm){
  .half-splash-content .properties-categories-short-wrapper {
    display:none;
  }
}