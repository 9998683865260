.bootstrap-select {
  .btn-default{
    background-color: #fff !important;
  }

  a:focus{
    outline: none;
  }

  .dropdown-menu>li{
    margin-bottom:0;

    a{

    }
  }
}

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover {
  background-color: #fff !important;
}